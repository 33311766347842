/* 公共处理dom节点 */
export const captchaModalVerify = async (res) => {
  const img = res.Image || res.CaptchaImage
  if (img) {
    $('#captcha').modal('show').find('.captcha-img').attr('src', img)
    if (res.Msg) {
      $('#captcha').find('.captcha-err').show().text(res.Msg)
    } else {
      $('#captcha').find('.captcha-err').hide()
    }
  }
}
export const clearCaptchaModal = () => {
  $('#captcha').modal('hide')
  $('#captcha').find('.captcha-err').text('').hide()
}
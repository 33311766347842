import $ from 'jquery'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss';
import './format.js'
import login from "./login"
import { getAuthInfo, removeAuthInfo, setAuthInfo, preRequestDeal, dealSms, getUrlQuery, setUrlQuery } from "./tool";
import { getAdminEnterprises, getMemberDetail, createImageCaptcha, logout, getCompanyConfig } from "./api";
import { captchaModalVerify, clearCaptchaModal } from "./dealDom"
import { FINANICALWEB, PERSONALWEB, REIMBURSEMENT, BILLCENTERWEB } from "./constant";

$(function () {
  initWebInfo()
  /* jq扩展方法 警告提示框 */
  $.extend({
    $msg: function (text, type, delay) {
      if (!type) type = 'alert-danger'
      if (!$('.toast').length) {
        const msgHtml = '<div class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${delay}"> <div class="toast-body"></div> </div>'.format({
          delay: delay || 2000
        })
        $('body').append(msgHtml)
      }
      $('.toast').toast('show').find(".toast-body").text(text).addClass(type)
      if (delay) {
        $('.toast').toast({
          delay
        })
      }
    },
    $init: function (type) {
      initUser(type)
    },
    $platform: function () {
      const pathnameList = window.location.pathname.split('/')
      const page = pathnameList[pathnameList.length - 1];
      let result = ''
      switch (page) {
        case 'financial.html':
          result = 'financial'
          break;
        case 'login.html':
          result = $.$urlQuery.get('platform') === 'enterprise' ? 'enterprise' : 'financial'
          break;
        case 'enterprise.html':
          result = 'enterprise'
          break;
        case 'agencyGov.html':
          result = 'agency'
          break;
        case 'personal.html':
          result = 'personal'
          break;
        case 'register.html':
          result = $.$urlQuery.get('platform')
          break;
        default:
          result = 'personal'
          if (process.env.WEBHOST === 'mobi') {
            result = 'financial'
          }
          break
      }
      return result
    },
    $getUserInfo: async function () {
      const res = await getMemberDetail()
      if (res.ResCode === 1000) {
        return res.Data
      }
    },
    $logout: function () {
      logout()
      const authInfo = getAuthInfo()
      const DeviceId = authInfo.DeviceId // 设备id先存起来可以不变
      $._userInfo = {}
      removeAuthInfo()
      setAuthInfo({ DeviceId })
      initLoginInfo()
      /* 注册页面 */
      if (window.location.pathname.includes('/register.html')) {
        const type = $.$urlQuery.get('type')
        const platform = $.$urlQuery.get('platform')
        window.location.replace('./register.html?$type=${type}&platform=${platform}'.format({
          type,
          platform
        }))
      }
    },
    $urlQuery: {
      get(key) {
        return getUrlQuery(key)
      },
      set(key, val, type, url) {
        return setUrlQuery(key, val, type, url)
      }
    },
    $WEBNAME: process.env.WEBNAME || '',
    $WEBLOGO: process.env.WEBLOGO || '',
    $message:function (params) {
      let config = {
        title: '',
        text: '',
        mask:true,
        success:function () {},
        ...params
      }
      
      const msgHtml = `<div class="psb-message-box ${config.mask?'mask':''}">
        <div class="psb-message-body">
          <div class="message-head">
            <h4 class="message-title">${config.title}</h4>
            <button type="button" class="psb-msg-close close" aria-label="关闭">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="message-content">${config.text}</div>
          <div class="message-footer">
            <button type="button" class="btn btn-primary psb-msg-confirm" >确定</button>
          </div>
        </div>
      </div>`
      $('body').append(msgHtml)
      if (config.success) {
        $('.psb-message-box .psb-msg-confirm').on("click",async function (params) {
          await config.success()
          $('.psb-message-box').remove()
        })
      }
    }
  })
  $('.toast').toast({
    delay: 2000
  })
  initUser()
  let topList = [{
    name: '企业',
    link: './financial.html'
  }]

  var topListHtml = ''
  for (const item of topList) {
    topListHtml += '<li class="nav-item"><a class="nav-link" href="${link}">${name}</a></li>'.format({
      link: item.link,
      name: item.name
    })
  }
  $('#top .navbar-nav').html(topListHtml)
  $('#footer .year-end').text(new Date().getFullYear())
})
login()
const initUser = async (type) => {
  preRequestDeal()
  await getMemberDetailInfo()
  await initLoginInfo()
  // $('#captcha').modal('show')
}
const initLoginInfo = async () => {
  const userInfo = $._userInfo
  /* 已登录状态 */
  if (userInfo && userInfo.UserId) {
    $._isLogin = true
    /* top userinfo */
    $('#top .userinfo .username').text(userInfo.MobilePhone)
    $('#top .userinfo').show()
    /* header userinfo */
    $('.header-right .logined-type .username').text(userInfo.MobilePhone)
    $('.header-right .unlogin').hide()
    $('.header-right .logined').show()
    if ($('.navbar-type').hasClass('show-nav-personal')) {
      $('.navbar-type').removeClass('hideRight')
    }
    /* agency-logined */
    $('.agency-unlogin').hide()
    $('.agency-logined').show().find('.username').text(userInfo.MobilePhone)
    /* personal */
    $('#fullpage .btn-wrap').hide()
    await getAdminEnterpriseInfo()
    if (window.location.pathname==='/'&&window.location.hostname!=='localhost' && window.location.hostname!=='127.0.0.1') {
      window.initSignal&&window.initSignal()
    }
    if(window.location.hostname.includes('mobi')&&$._bindEnterprises.length){
      window.entryPsb('financial')
      return;
    }
  } else {
    $._isLogin = false
    $('#fullpage .btn-wrap').show()
    $('#top .userinfo').hide()
    if ($('.navbar-type').hasClass('show-nav-personal')) {
      $('.header-right').hide()
    } else {
      $('.header-right .unlogin').show()
      $('.header-right .logined').hide()
    }
    /* agency-logined */
    $('.agency-unlogin').show()
    $('.agency-logined').hide()
  }
}
const getMemberDetailInfo = async () => {
  const res = await getMemberDetail()
  if (res.ResCode === 1000) {
    $._userInfo = res.Data
    $._isLogin = true
    /* 已登录页面处理 */
    const id = $.$urlQuery.get('id') // 有id的是邀请开通或是待开通
    if (!id) {
      $('#public-reg .admin-info .unlogin').hide()
      $('#public-reg .admin-info .logined').show().find('.logined-tel').text($._userInfo.MobilePhone)
    }
  }
}
const getAdminEnterpriseInfo = async () => {
  const reqdata = {
    Token: $._userInfo.Token
  }
  if ($.$platform() === 'agency') {
    reqdata.IsAgency = true
  }
  const res = await getAdminEnterprises(reqdata)
  if (res.ResCode === 1000) {
    $._bindEnterprises = res.BindEnterprises
    if ($.$platform() === 'agency' && res.BindEnterprises.length > 0) { // 如果是通过注册财税机构后刷新页面了，就让它直接进去
      const currId = $.$urlQuery.get('currId')
      if (currId) {
        const currEnterprise = res.BindEnterprises.find(o => o.Id === currId)
        if (currEnterprise && currEnterprise.Status == 2) {
          setAuthInfo({ EnterpriseId: currId })
          setTimeout(() => {
            window.location.replace(BILLCENTERWEB)
          }, 500);
        }
      }

    }
  }
}

const testFun = () => {
  const res = { "ResCode": 1008, "Msg": "", "CaptchaImage": "http://test.mypsb.cn/api/Files/Temp/20210323/CaptchaImage/210/R_YT5CHJKQWHHS/5990e7d9-a78e-4138-864b-51f0185fa571.png", "WarnMessage": "" }
  captchaModalVerify(res)
}
window.entryPsb = (source) => {
  let url = '', msg = '';
  switch (source) {
    case 'financial':
      if ($._bindEnterprises.length === 0) {
        msg = '您还未注册企业账号'
        url = './register.html?type=0&platform=financial'
      } else {
        url = FINANICALWEB
      }
      break;
    case 'agency':
      if ($._bindEnterprises.length === 0) {
        msg = '您还未注册财税机构账号'
        url = './register.html?type=0&platform=agency'
      } else {
        const successAgency = $._bindEnterprises.find(o => o.Status == 2)
        if (successAgency) {
          setAuthInfo({
            EnterpriseId: successAgency.Id
          })
          url = BILLCENTERWEB
        } else {
          msg = '您还未注册财税机构账号'
          url = './register.html?type=0&platform=agency'
        }
      }
      break;
    case 'enterprise':
      if ($._bindEnterprises.length === 0) {
        msg = '您还未注册电子报销账号'
        url = './register.html?type=0&platform=enterprise'
      } else {
        url = REIMBURSEMENT
      }
      break;
    default:
      url = PERSONALWEB
      break;
  }
  if (msg) {
    $.$msg(msg)
    setTimeout(() => {
      window.location.replace(url)
    }, 1500);
  } else {
    window.location.replace(url)
  }
}

/* 
  图形码操作
 */
$('#captcha').on("click", '.captcha-img', async function () {
  const res = await createImageCaptcha()
  captchaModalVerify(res)
})
$('#captcha').on("click", ".submit-btn", async function () {
  $(this).attr('disabled', true)
  const captchaModal = $('#captcha')
  const CaptchaCode = captchaModal.find('.captcha-input input').val()
  if (!CaptchaCode) {
    $.$msg('请输入图形码')
    $(this).attr('disabled', false)
    return
  }

  const reqdata = {
    CaptchaCode,
    Account: $('#login-tel .tel-input').val(),
    FlagType: 0
  }
  const smsRes = await dealSms(reqdata)
  $(this).attr('disabled', false)
  if (smsRes.ResCode !== 1000) {
    if (smsRes.ResCode === 1008) {
      smsRes.Msg = '验证码输入错误'
    }
    captchaModalVerify(smsRes)
  } else {
    clearCaptchaModal() // 图形验证码通过
  }
})
$('#captcha').on("focus", ".captcha-input input", function () {
  $('.captcha-err').text('').hide()
})

/* 根据域名配置logo */
function initLogoCompany() {
  getCompanyConfig().then(data => {
    const hostname = location.hostname
    let config = data['mypsb.cn']
    if (data[hostname] && data[hostname].hostname !== 'mypsb.cn') {
      config = data[hostname]
      $('.logo-img').attr('src', config.logo).show()
      $('.header-nav .logo').addClass('third-logo')
    } else {
      $('.logo-img').show()
    }

  }).catch(e => {
    $('.logo-img').show()
  })
}
/* 验证企业是否通过审核 */
function verifyEnterprise() {
  if ($.$platform() !== 'personal') {
    const passEnterprise = $._bindEnterprises.find(o => o.Status == 2)
    if (passEnterprise) {

    }
  }
}
$('body').on("click", ".psb-msg-close", function () {
  $(this).parents('.psb-message-box').remove()
})
function initWebInfo() {
    if (process.env.WEBHOST === 'ifp') {
      $('#footer .info-item-company').html("广州湛蓝数据科技有限公司成立于2016年，专注为企业提供一站式发票管理平台，<br/>提供票面数据智能解析，发票合规性管理，发票自动防伪查验、移动报销，电子发票存档的进项收票管理能力。")
      $('#footer .footer-logo').attr("src", require("@/assets/imgs/logo-ifp2.png"))
      $('.header-nav .logo .logo-img').attr("src", require("@/assets/imgs/logo-ifp.png"))
      $('#footer .copyright-box .enterprise-name').text("广州湛蓝数据科技有限公司")
      $('#footer .copyright-box .enterprise-record').text("粤ICP备18023998号")
    }else if (process.env.WEBHOST === 'mobi') {
      $('#footer .info-item-company').html("广州湛蓝数据科技有限公司成立于2016年，专注为企业提供一站式发票管理平台，<br/>提供票面数据智能解析，发票合规性管理，发票自动防伪查验、移动报销，电子发票存档的进项收票管理能力。")
      $('#footer .footer-logo').attr("src", require("@/assets/imgs/logo-mobi2.png"))
      $('.header-nav .logo .logo-img').attr("src", require("@/assets/imgs/logo-mobi.png"))
      $('#footer .copyright-box .enterprise-name').text("广州湛蓝数据科技有限公司")
      // $('#footer .company-info').css('display', 'none')
      $('#footer .copyright-box .enterprise-record').text("粤ICP备18023998号")
    }else {
      
    }
    $('.header-nav .header-left .logo .logo-img').show()
  
}
$('.fixed-section .arrow').on("click", function () {
  if ($(this).parent().hasClass('show')) {
    $(this).parent().removeClass("show")
  }else {
    $(this).parent().addClass("show")
  }
})
const ZHANLANAUTHINFO = 'ZHANLANAUTHINFO'
import { PREREQUESTTIME } from "./constant"
import { getProtectionToken, searchInvoiceTitles, ticketInvoice, sendSms } from "./api"
import { sec } from './base64'
const md5 = require('md5')
const sha256 = require('sha256')
const authInfo = {
  DeviceId: '',
  Latlng: '',
  LoginId: '',
  Platform: 'PC',
  ApiVersion: 12,
  Token: '',
  RefreshToken: '',
  CurrentUserId: '',
  CurrentUserName: '',
  ClientAppCode: window.location.hostname.includes('apiao.cn')?'apiao_invoiceFolder':'ifp_invoiceFolder',
}
/**
 * [通过参数名获取url中的参数值]
 * @param  {[string]} queryName [参数名]
 * @return {[string]}           [参数值]
 */
export const getUrlQuery = (queryName) => {
  var reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURI(r[2]);
  } else {
    return null;
  }
}
export const setUrlQuery = (name, value, jump = true, url = window.location.href) => { // jump直接跳转到改路由
  var r = url;
  if (r != null && r != 'undefined' && r != "") {
    value = encodeURIComponent(value);
    var reg = new RegExp("(^|)" + name + "=([^&]*)(|$)");
    var tmp = name + "=" + value;
    if (url.match(reg) != null) {
      r = url.replace(eval(reg), tmp);
    }
    else {
      if (url.match("[\?]")) {
        r = url + "&" + tmp;
      } else {
        r = url + "?" + tmp;
      }
    }
  }
  if (jump) {
    window.location.href = r
  } else {
    return r;
  }
}
/**
 * localStorage
 * @param {any} name
 * @returns {any} localStorage
 */
export const getStore = name => {
  if (!name) throw new Error('store name 不能为空')
  let res = window.localStorage.getItem(name)
  let temp = {}
  if (res) {
    try {
      temp = JSON.parse(res)
      temp = typeof temp === 'object' ? temp : res
    } catch (error) { }
  }
  return temp
}
/**
 * 存储sessionStorage
 * @param {any} name
 * @param {any} content
 */
export const setStore = (name, content) => {
  if (!name) throw new Error('store name 不能为空')
  if (typeof content !== 'object') throw new Error('store 只能设置对象值')
  let store = getStore(name)
  Object.assign(store, content)
  content = JSON.stringify(store)
  window.localStorage.setItem(name, content)
}
/**
 * 清除localStorage
 * @param {any} name
 * @param {any} content
 */
export const removeStore = name => {
  if (!name) throw new Error('store name 不能为空')
  window.localStorage.removeItem(name)
}
/**
 * 获取sessionStorage
 * @param {any} name
 * @returns {any} sessionStorage
 */
export const getSessionStore = (name, deCompile) => {
  if (!name) return null
  let res = window.sessionStorage.getItem(name)
  let temp = {}
  if (res) {
    if (deCompile) {
      temp = JSON.parse(res)
    } else {
      let uncompileRes = uncompile(res)
      temp = JSON.parse(uncompileRes)
    }
    temp = typeof temp === 'object' ? temp : res
  }
  // if (name === ISKNOWNABNORMALINVOICE) {
  //   console.log(temp)
  // }
  return temp
}
/**
 * 存储sessionStorage
 * @param {any} name
 * @param {any} content
 */
export const setSessionStore = (name, content, deCompile) => {
  if (!name) return
  let store = getSessionStore(name, deCompile)
  Object.assign(store, content)
  content = JSON.stringify(store)
  if (!deCompile) {
    content = compile(content)
  }

  window.sessionStorage.setItem(name, content)
}
/**
 * 获取authInfo
 */
export const getAuthInfo = () => {
  let store = getStore(ZHANLANAUTHINFO) || {}
  let newValue = Object.assign({}, authInfo, store)
  return newValue
}

/**
 * 设置authInfo
 * @param {*} content 新的内容
 */
export const setAuthInfo = content => {
  let old = getAuthInfo()
  let newValue = Object.assign({}, old, content)
  return setStore(ZHANLANAUTHINFO, newValue)
}

/**
 * 移除authInfo
 */
export const removeAuthInfo = () => {
  removeStore(ZHANLANAUTHINFO)
}
/* 验证手机号 */
export const verifyMobilePhone = (mobilePhone) => {
  if (!mobilePhone || !/^1\d{10}$/.test(mobilePhone)) {
    return '请填写正确的手机号'
  }
  return null
}
export const loading = (state) => {
  var target = document.querySelector('.psb-loading');
  if (!!state) {
    if (!!target) {
      target.style.display = 'block';
    } else {
      var fragment = document.createDocumentFragment();
      var div = document.createElement('div');
      div.className = 'psb-loading';
      div.innerHTML = `<div class="d-flex justify-content-center align-items-center">
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>`;
      fragment.appendChild(div);
      document.getElementsByTagName('body')[0].appendChild(fragment);
    }
  } else {
    if (!!target) {
      target.style.display = 'none';
    }
  }
}

/**
 * @desc 函数防抖
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param immediate true 表立即执行，false 表非立即执行
 */
export const debounce = (func, wait, immediate) => {
  let timeout;
  return function () {
    let context = this;
    let args = arguments;
    if (timeout) clearTimeout(timeout);
    if (immediate) {
      var callNow = !timeout;
      timeout = setTimeout(() => {
        timeout = null;
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timeout = setTimeout(function () {
        func.apply(context, args)
      }, wait);
    }
  }
}

/**
 * @desc 函数节流
 * @param func 函数
 * @param wait 延迟执行毫秒数
 * @param type 1 表时间戳版，2 表定时器版
 */
export const throttle = (func, wait, type) => {
  if (type === 1) {
    var previous = 0;
  } else if (type === 2) {
    var timeout;
  }
  return function () {
    let context = this;
    let args = arguments;
    if (type === 1) {
      let now = Date.now();

      if (now - previous > wait) {
        func.apply(context, args);
        previous = now;
      }
    } else if (type === 2) {
      if (!timeout) {
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(context, args)
        }, wait)
      }
    }
  }
}

export const searchConpany = debounce(async (ele, selectEle) => {
  const val = $(ele).val()
  let callBackData = null
  const res = await getProtectionToken()
  if (res.ResCode === 1000) {
    callBackData = getSecObj(res)
  } else if (res.ResCode === 1007) {
    await sendPreDeal()
  }
  if (!callBackData) {
    $.$msg('数据加密异常...', 'alert-error')
    return
  }
  let companyRes = await searchInvoiceTitles({
    PartialName: val,
    Protection: callBackData.Protection
  })
  if (companyRes.ResCode === 1007) {
    await sendPreDeal()
    companyRes = await searchInvoiceTitles({
      PartialName: val,
      Protection: callBackData.Protection
    })
  }
  if (companyRes.ResCode === 1000) {
    let li = ``
    for (const item of companyRes.Items) {
      li += `<li data-code=${item.Code}>${item.Name}</li>`
    }
    $(selectEle).html(li)
    $(ele).dropdown('toggle')
  }
}, 500, false)

export const getSecObj = data => {
  let address = JSON.parse(sec()).Secs
  let secondTotal =
    (Date.parse(data.Timestamp.replace(/-/g, '/')) -
      Date.parse('1990/01/01 00:00:00')) /
    1000
  let mod = Math.floor(secondTotal % address.length)
  let curSecret = address[mod]
  let dealSec = {
    ProtectionToken: data.ProtectionToken,
    Timestamp: data.Timestamp,
    Secret: curSecret
  }
  let secJson = `${dealSec.ProtectionToken},${dealSec.Timestamp},${dealSec.Secret}`
  let shaRes = sha256(secJson)
  let md5Res = md5(shaRes).toUpperCase()
  return {
    Protection: {
      Version: 1,
      ProtectionToken: data.ProtectionToken,
      Timestamp: data.Timestamp,
      Signature: md5Res
    }
  }
}
export const dealProtectionToken = async () => {
  let callBackData = null
  const res = await getProtectionToken()
  if (res.ResCode === 1000) {
    callBackData = getSecObj(res)
  } else if (res.ResCode === 1007) {

  }
  if (!callBackData) {
    $.$msg('数据加密异常...', 'alert-error')
    return
  }
  return callBackData
}

export const getPreRequestTime = () => {
  let store = getStore(PREREQUESTTIME) || {}
  let newValue = Object.assign({}, authInfo, store)
  return newValue
}
export const setPreRequestTime = content => {
  let old = getAuthInfo()
  let newValue = Object.assign({}, old, content)
  return setStore(PREREQUESTTIME, newValue)
}
export const removePreRequestTime = () => {
  removeStore(PREREQUESTTIME)
}
export const sendPreDeal = async () => {
  const res = await ticketInvoice()
  if (res && res.ResCode === 1000) {
    setPreRequestTime({ time: new Date(Date.now()).toLocaleDateString() })
    return res
  }
}
// 接口预处理
export const preRequestDeal = async () => {
  let preTime = getPreRequestTime()
  if (preTime) {
    let currDate = new Date().getTime() / 1000
    let linchenTime =
      new Date(new Date(preTime.time).setHours(0, 0, 0, 0)).getTime() / 1000
    let afterDayTime = linchenTime + 86400
    if (currDate > linchenTime && currDate < afterDayTime) {
      return
    } else {
      return await sendPreDeal()
    }
  } else {
    return await sendPreDeal()
  }
}

// 短信验证码
export const dealSms = async (data = {}) => {
  const ProtectionToken = await dealProtectionToken()
  const smsReqdata = {
    ...data,
    Protection: ProtectionToken.Protection
  }
  let smsRes = await sendSms(smsReqdata)
  if (smsRes.ResCode === 1007) {
    await sendPreDeal()
    smsRes = await sendSms(smsReqdata)
  }
  return smsRes
}
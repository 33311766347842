import './common/base.js'
import '@/scss/common/base.scss'
import '@/scss/news.scss'
import {
  getDBUIActionConfigs
} from "./common/api"
window.onload = function () {
  const id = $.$urlQuery.get('id')
  if (id && id !== 'preview' && id !== 'default') {
    getUIActionNews().then(newsList => {
      const current = newsList.find(item => item.Id === id)
    if (current) {
      renderHtml(current)
    }
    })
  }
}


function renderHtml(data) {
  const {
    title,
    createtime,
    content
  } = data || {}
  if (title) {
    $('.news-title').text(title)
  }
  if (createtime) {
    $('.news-createtime').text(createtime)
  }
  if (content) {
    $('.news-content').html(content)
  }
}
export const getUIActionNews = async () => {
  const res = await getDBUIActionConfigs({
    UITypes: ['webNews'],
    AppId: 'web-ifp-bc'
  })
  let result = []
  if (res.ResCode === 1000) {
    res.Data.forEach(item => {
      item.ExtendSets.forEach(o => {
        item[o.Key] = o.Value
      })
    })
    result = res.Data
  }
  return result
}
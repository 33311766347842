/*
 * @Author: lijianhui 
 * @Date: 2020-09-01 16:01:44 
 * @Last Modified by: lijianhui
 * @Last Modified time: 2021-07-01 11:31:23
 */
let APIPATH = `${process.env.BASE_PROTOCOL}://${process.env.BASE_URL}/api`
const origin = window.location.origin
APIPATH = `${origin}/api`
export const VERSION = Math.floor(Math.random() * 20) // 版本
export const PREREQUESTTIME = 'PREREQUESTTIME'
const LINEVERSION = 3
// 各个平台地址
let PERSONALWEB = `${APIPATH}/web${LINEVERSION}/site/psbweb/index.html#/invoiceList` // 个人端

// export const PERSONALWEB = `${APIPATH}/web${LINEVERSION}/site/psbweb/index.html#/invoiceList` // 个人端
export const FINANICALWEB = `${APIPATH}/web${LINEVERSION}/finanical/index.html#/main` // 企业票夹
export const BILLCENTERWEB = `${APIPATH}/web${LINEVERSION}/billcenter/index.html#/financialIns/customList` // 财税机构
export const REIMBURSEMENT = `${APIPATH}/web${LINEVERSION}/billcenter/index.html#/home` // 电子报销
if (process.env.WEBNAME) {
  PERSONALWEB = `${APIPATH}/web${LINEVERSION}/psbweb/index.html#/invoiceList`
}
export { PERSONALWEB, APIPATH }
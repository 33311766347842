import './common/base.js'
import '@/scss/common/base.scss'
import '@/scss/login.scss'
import { v4 as uuidv4 } from 'uuid';
import {setAuthInfo} from './common/tool'
let uuid = uuidv4().replace(/-/g, '')
let connection
let connectTime = 0 // 连接次数
$(function () {
  const mobile = $.$urlQuery.get('mobile')
  const type = $.$urlQuery.get('type')
  if (mobile) {
    $('#login-tel .tel-input').val(mobile)
  }
  if (type === '2') {
    $('#login-box .tab-nav a').removeClass('active')
    $("#login-box .tab-nav a[data-type='sms']").removeClass('active')
    $('#login-password').removeClass('active')
    $('#login-sms').addClass('active')
  }
  console.log(111111);
})
window.addEventListener("load", function () {
  setTimeout(() => {
    $('.switch-login').addClass('qr')
    getMiniAppQrCode()
    if (!['localhost','127.0.0.1'].includes(location.hostname)) {
      $('.switch-login').addClass('qr')
      initSignal()
    }
  }, 500);
})
function getMiniAppQrCode() {
  let AppId = 'wx75997b123b9855a0'
  const Scene = encodeURIComponent(`PushId=${uuid}&type=scanLogin&sourceName=云票夹`)
  const Page = encodeURIComponent('subPages/scanLogin')
  const img = `${location.origin}/api/RedPacket/GetMiniAppQrCode?AppId=${AppId}&Scene=${Scene}&Page=${Page}`
  $('#appletCode').attr('src', img)
  $('#appletCode').attr('alt', process.env.WEBNAME +','+process.env.BASE_URL||'票易得,apiao.cn')
}
function initSignal() {
  connection = new signalR.HubConnectionBuilder().withUrl(window.location.origin+'/pushHub').build()
  connection.onclose(async (err) => {
    console.log('onclose',err)
  })
  connection.on("OnPush",function (msg) {
    console.log('OnPush',msg);
    if (msg.messageType ===6) {
      dealMsgEvent(msg.message)
    }
  })
  async function start() {
    try {
      await connection.start()
      console.log('SignalR Connected.')
      connectTime = 0
      /* connection.invoke('Login', {
        MessageType: 1,
        Token: authInfo.Token,
        PushId: uuid
      }) */
      /* 订阅消息 */
      connection
        .invoke('Subscribe', {
          Types: [1, 2],
          ReceiveId: uuid,
          PublishId: 'mini'+uuid,
        })
        .catch(function (err) {
          console.error('Subscribe', err)
        })
    } catch (err) {
      console.log(err)
      if(connectTime<3) {
        connectTime+=1
        setTimeout(start, 5000)
      }
    }
  }
  start()
}
async function dealMsgEvent(message){
  if (message.JData.type === 'scanLogin'&&message.JData.Token) {
    setAuthInfo({
      Token:message.JData.Token
    })
    await $.$init(true)
    setTimeout(() => {
      window.entryPsb('financial')
    }, 500);
  }
}
window.addEventListener('offline', function () {
  connection&&connection.stop()
})
window.addEventListener('beforeunload', function (e) {
  connection&&connection.stop()
});
import './common/base.js'
import '@/scss/common/base.scss'
import '@/scss/price.scss'
import {
  v4 as uuidv4
} from 'uuid';
import {
  webOpenUserPackage,
  getCostDisplay
} from "./common/api"
import {
  getAuthInfo,
  getUrlQuery,
  setAuthInfo
} from './common/tool'
let connection
let uuid = uuidv4().replace(/-/g, '')
let connectTime = 0 // 连接次数
$(function () {
  window.initSignal = () => {
    connection = new signalR.HubConnectionBuilder().withUrl(window.location.origin + '/pushHub').build()
    connection.onclose(async (err) => {
      console.log('onclose', err)
    })
    connection.on("OnPush", function (msg) {
      console.log('OnPush', msg);
      if (msg.messageType === 9) {
        dealPayEvent(msg.message.JData)
      }
    })
    async function start() {
      try {
        await connection.start()
        console.log('SignalR Connected.')
        connectTime = 0
        const authInfo = getAuthInfo()
        let loginData = {
          MessageType: 1,
          Token: authInfo.Token,
          // ConnectType: 'wxRoot',
          PushId: authInfo.CurrentUserId,
          UserId: authInfo.CurrentUserId
        }
        connection.invoke('Login', loginData)

      } catch (err) {
        console.log(err)
        if (connectTime < 3) {
          connectTime += 1
          setTimeout(start, 5000)
        }
      }
    }
    start()
  }
  /* 点击购买 */
  $('.package-list').on("click", ".pay-btn", async function (params) {
    if (!$._userInfo || !$._userInfo.UserId) {
      $.$message({
        title: '提示',
        text: '请先登录账号',
        success() {
          window.location.replace(`./login.html?platform=financial&cb=${encodeURIComponent(window.location.href)}`)
        }
      })
      return
    }
    if (!$._bindEnterprises || !$._bindEnterprises.length) {
      $.$msg('您还未注册企业票夹账号,请先注册')
      setTimeout(() => {
        window.location.replace(`./register.html?type=0&platform=financial&cb=${encodeURIComponent(window.location.href)}`)
      }, 1500)
      return
    }
    const buyType = $(this).data('buy-type')
    let enterList = ``
    for (const item of $._bindEnterprises) {
      enterList += `<a class="dropdown-item" href="javascript:;" data-id="${item.Id}" data-name="${item.Name}">${item.Name}</a>`
    }
    $('.select-enterprise-list').html(enterList)
    const payItem = JSON.parse($(this).parents('.package-item').attr('data-item'))
    $('#payModal').attr('data-package-id', payItem.Id).attr('data-buy-type', buyType)
    const res = await selectEnterprise($._bindEnterprises[0])
    $('#payModal .package-name').text(payItem.DisplayName)
    $('#payModal .item-price span').text(payItem.NewcomerPrice || payItem.PackagePrice || payItem.OriginalPrice)
    if (payItem.NewcomerPrice || payItem.PackagePrice) {
      $('#payModal .original-price').show().children('span').text(payItem.OriginalPrice)
    } else {
      $('#payModal .original-price').hide()
    }
    if (buyType === 'number' && res.ResCode !== 1000) {
      $('.toast').remove()
      $.$message({
        title: '提示',
        text: res.Msg
      })
      return
    }
    $('#payModal').modal('show')
  })

  getCostDisplayApi()
  async function getCostDisplayApi() {
    const res = await getCostDisplay({
      AppId: window.location.hostname.includes('apiao.cn') ? 'web-apiao-bc' : 'web-ifp-bc',
      PackageType: 'Enterprise',
      OtherPackageTypes: ['Enterprise_Basis']
    })
    if (res.ResCode === 1000) {
      renderCostList(res)
      const priceType = getUrlQuery('type')
      if (res.OtherDatas && res.OtherDatas.Enterprise_Basis) {
        $('.add-package').show()
      } else {
        $('.add-package').hide()
      }
      $('.tool-package').hide()
      if (priceType === '1') {
        $('.add-package').show()
        $('.base-package').hide()
      }else if(priceType === '2') {
        $('.add-package').hide()
        $('.base-package').show()
      }else if (priceType === '3') {
        $('.tool-package').show()
        $('.add-package').hide()
        $('.base-package').hide()
      }
    }
  }
  const renderCostList = (data) => {
    const datePackages = data.Data.DatePackages
    // const numberPackages = data.NumberPackages
    let dateHtml = ``
    for (const item of datePackages) {
      dateHtml += `<div class="package-item col-lg-3 col-sm-6  col-10" data-item='${JSON.stringify(item)}'>
      <div class="package-item-wrap">
        <div class="item-top">
          <div class="item-title">${item.DisplayName}</div>
          <p class="desc2">有效期${item.Circle.Count}${item.Circle.Type===1?'个月':item.Circle.Type===2?'年':'天'}</p>
        </div>
        <div class="item-bottom ">
          <div class="item-price">￥${ item.NewcomerPrice || item.PackagePrice || item.OriginalPrice }</div>
          <p class="original-price">${(item.NewcomerPrice || item.PackagePrice)?'￥'+item.OriginalPrice:''}</p>
          <a href="javascript:;" class="pay-btn" data-buy-type='date'>立即购买</a>
        </div>
      </div>
    </div>`
    }
    $('.package-list.date-list').html(dateHtml)
    if (data.OtherDatas && data.OtherDatas.Enterprise_Basis) {
      const baseList = data.OtherDatas.Enterprise_Basis.DatePackages
      let numberHtml = ``
      for (const item of baseList) {
        numberHtml += `<div class="package-item col-lg-3 col-sm-6  col-12" data-item='${JSON.stringify(item)}'>
        <div class="package-item-wrap">
          <div class="item-top">
            <div class="item-title">${item.DisplayName}</div>
            <p class="desc2">有效期${item.Circle.Count}${item.Circle.Type===1?'个月':item.Circle.Type===2?'年':'天'}</p>
          </div>
          <div class="item-bottom ">
            <div class="item-price">￥${item.OriginalPrice}</div>
            <a href="javascript:;" class="pay-btn" data-buy-type='number'>立即购买</a>
          </div>
        </div>
      </div>`
      }
      $('.package-list.number-list').html(numberHtml)
    }

  }
  const selectEnterprise = async (data) => {
    if (data) {
      $('#enterpriseList .select-name').text(data.Name).attr('data-id', data.Id)
    }
    let reqdata = {
      PackageType: 'Enterprise',
      PaymentPlatform: 0,
      PackageCount: 1,
      PackageId: $('#payModal').attr('data-package-id'),
      EnterpriseId: $('#enterpriseList .select-name').attr('data-id'),
      Tenant: 'Piaoyide',
      AppId: window.location.hostname.includes('apiao.cn') ? 'web-apiao-bc' : 'web-ifp-bc'
    }
    $('#payModal .pay-code-loading').show()
    $('#payModal .pay-code-img').hide()
    $('#payModal .pay-code-error').hide()
    // const res = await mock()
    const res = await webOpenUserPackage(reqdata)
    $('#payModal .pay-code-loading').hide()
    if (res.ResCode === 1000) {
      $('#payModal .pay-code-img').attr('src', res.Data.QrCode).show()
      $('#payModal .pay-code-error').hide()
      $('#payModal .pay-tip').show().children('span').text(Math.floor(res.Data.ClientExpireTime / 60))
    } else {
      $.$msg(res.Msg)
      $('#payModal .pay-code-img').hide()
      $('#payModal .pay-code-error').css('display', 'flex')
      $('#payModal .pay-tips').hide()
    }
    return res
  }
  $('.enterprise-list').on("click", ".select-enterprise-list .dropdown-item", function () {
    selectEnterprise({
      Name: $(this).data('name'),
      Id: $(this).data('id'),
    })
  })
  $('.pay-code-error').on("click", function (params) {
    selectEnterprise()
  })
  const dealPayEvent = (msgData) => {
    if (msgData.IsSuccess) {
      $.$msg('支付成功！', 'alert-success')
      setAuthInfo({
        EnterpriseId: $('#payModal #enterpriseList .select-name').attr('data-id')
      })
      setTimeout(() => {
        window.entryPsb('financial')
      }, 1000);
    } else {
      $.$msg('支付失败，请重试！')
      $('#payModal').modal('hide')
    }
  }

  $('.radio-item').on("click", function () {
    $(this).addClass('active').siblings().removeClass('active')
  })
})
async function mock() {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        ResCode: Math.random() > 0.5 ? 1000 : 1001,
        Data: {
          QrCode: 'http://test.mypsb.cn/api/Files/PaymentCode/20211102/227/R_SA8TMH9CCX2J/d8ea503055da499f9ada47bb1a15887b.png'
        }
      })
    }, 500);
  })
}
window.addEventListener('offline', function () {
  connection && connection.stop()
})
window.addEventListener('beforeunload', function (e) {
  connection && connection.stop()
});